import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import CustomBottomNavigation from '../common/BottomNavigationAction';
import { FaPrayingHands } from 'react-icons/fa';

const SujetPriereForm = () => {
  const [sujet, setSujet] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!sujet.trim()) {
      alert('Le sujet de prière ne peut pas être vide.');
      return;
    }

    setSubmitting(true);

    try {
      // Envoyer la demande POST pour soumettre le sujet de prière
      const url = `${process.env.REACT_APP_API_ROOT}/soumettre-sujet-de-priere`;
      //'http://localhost/wpheadless/server/wp-json/wp/v2/soumettre-sujet-de-priere'
      const response = await axios.post(url, {
        sujet: sujet.trim(),
      });

      if (response.status === 200) {
        // Utiliser SweetAlert pour afficher un message après une soumission réussie
        Swal.fire({
          title: 'Sujet de prière soumis avec succès!',
          text: 'Il sera confié au comité d\'intercession de l\'église.',
          icon: 'success',
          iconHtml: '<i class="far fa-thumbs-up"></i>', // Optionnel : icône personnalisée
          customClass: {
            popup: 'text-md', // Définir la taille de la police pour le texte du pop-up
          },
        });

        // Réinitialiser le formulaire après la soumission réussie
        setSujet('');
      } else {
        // Afficher une alerte SweetAlert en cas d'erreur
        Swal.fire({
          title: 'Erreur!',
          text: 'Erreur lors de la soumission du sujet de prière.',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Erreur lors de la soumission du sujet de prière:', error);

      // Afficher une alerte SweetAlert en cas d'erreur
      Swal.fire({
        title: 'Erreur!',
        text: 'Erreur lors de la soumission du sujet de prière.',
        icon: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div style={{ padding: 20, marginTop: '100px' }}>
        <div className="bg-gray-100 flex items-center justify-center">
          <div className="bg-white p-8 rounded shadow-md w-full md:w-1/2 lg:w-1/3 relative">
          <div className="text-sm text-gray-500 mb-4 text-center">
              Pas besoin de révéler votre identité, laissez votre sujet de prière de manière anonyme.
            </div>
            <h2 className="text-2xl font-bold mb-4 text-center" style={{ fontSize: 22 }}>
              <FaPrayingHands className="inline-block mr-2" />
              Corbeille de Prières
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Sujet de Prière:</label>
                <textarea
                  className="w-full p-2 border rounded"
                  value={sujet}
                  onChange={(e) => setSujet(e.target.value)}
                  rows="4"
                  disabled={submitting}
                />
              </div>
              <button
                className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-all duration-300 relative"
                type="submit"
                disabled={submitting}
              >
                {submitting && (
                  <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                    <div className='w-4 h-4 rounded-full border-4 border-b-0 border-blue-500 animate-spin' />
                  </div>
                )}
                {submitting ? 'Envoi en cours...' : 'Soumettre Sujet de Prière'}
              </button>
            </form>
          </div>
        </div>
      </div>
      <CustomBottomNavigation />
    </>
  );
};

export default SujetPriereForm;
