import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';

const SliderComponent = () => {
  const [slides, setSlides] = useState([]);
  const url = `${process.env.REACT_APP_API_ROOT_SLIDES}/slides/`;

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await axios.get(url);
        setSlides(response.data);
      } catch (error) {
        console.error('Error fetching slides:', error);
      }
    };

    fetchSlides();
  }, []);

  const NextArrow = (props) => (
    <div className="slick-arrow slick-next" {...props}>
      Next
    </div>
  );

  const PrevArrow = (props) => (
    <div className="slick-arrow slick-prev" {...props}>
      Prev
    </div>
  );

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: (i) => (
      <button
        style={{
          width: "10px",
          height: "10px",
          margin: "2px 5px 4px 4px",
          borderRadius: "50%",
          backgroundColor: i === 0 ? "#fff" : "rgba(255, 255, 255, 0.5)",
          border: "none",
          outline: "none",
          cursor: "pointer",
        }}
      />
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  };

  return (
    <Slider {...sliderSettings} className="" style={{marginTop:'7px'}}>
      {slides.map((slide) => (
        <div key={slide.ID} className="slide-container">
          <a href={slide.lien} target="_blank" rel="noopener noreferrer">
          <div className="slide-overlay">
{/*
              <center>
                <h3 style={{fontSize:16,width:'70%'}} className="slide-title">{slide.post_title}</h3>
              </center>
 */}
          </div>
            <img
              src={slide.thumbnail_url || 'URL_PAR_DEFAUT'}
              alt={slide.post_title}
              style={{ width: '100%' }}
            />            
          </a>
{
  /*
            <button
            className="click-button"
            onClick={() => window.open(slide.lien, '_blank')}
          >
            cliquez ici
          </button>
   */
}
        </div>
      ))}
    </Slider>
  );
};

export default SliderComponent;
