import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import he from 'he';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faCalendar,faEye } from "@fortawesome/free-solid-svg-icons";
import {
  FacebookShareButton,
  WhatsappShareButton,
} from 'react-share';
import Footer from '../Footer';
import CustomBottomNavigation from '../common/BottomNavigationAction';

const CategoryArticles = () => {
  const { categoryId } = useParams();
  const [category, setCategory] = useState({});
  const [categoryPosts, setCategoryPosts] = useState([]);
  const perPage = 16;
  const [loading, setLoading] = useState(true);
  const [postDates, setPostDates] = useState({});
  const [postVisits, setPostVisits] = useState({});
  const [noResults, setNoResults] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchData = async () => {
    try {
      const categoryUrl = `${process.env.REACT_APP_API_ROOT}/categories/${categoryId}`;
      const categoryResponse = await axios.get(categoryUrl);
      setCategory(categoryResponse.data);

      const postsUrl = `${process.env.REACT_APP_API_ROOT}/posts?categories=${categoryId}&per_page=${perPage}&page=${currentPage}`;
      const postsResponse = await axios.get(postsUrl);

      // Vérifier s'il y a des résultats
      if (postsResponse.data.length === 0) {
        setNoResults(true);
      } else {
        setCategoryPosts(postsResponse.data);
        const { data, headers } = postsResponse;
        setTotalPages(Number(headers['x-wp-totalpages']));

        // Nouvelle requête pour obtenir les dates d'ajout des articles
        const dates = await Promise.all(
          postsResponse.data.map(async (post) => {
            const postUrl = `${process.env.REACT_APP_API_ROOT}/posts/${post.id}`;
            const postResponse = await axios.get(postUrl);
            return { id: post.id, date: postResponse.data.date };
          })
        );

        // Mettez à jour l'état des dates
        const datesObject = dates.reduce((acc, date) => {
          acc[date.id] = date.date;
          return acc;
        }, {});

        setPostDates(datesObject);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const incrementArticleVisits = async (postId) => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/increment-article-visits/${postId}`;
      await axios.post(url);
    } catch (error) {
      console.error(`Error incrementing article visits for post ${postId}:`, error);
    }
  };

  const fetchPostVisits = async (postId) => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/post-visits/${postId}`;
      const response = await axios.get(url);
      const visits = response.data;
      setPostVisits((prevVisits) => ({
        ...prevVisits,
        [postId]: visits,
      }));
    } catch (error) {
      console.error(`Error fetching post visits for post ${postId}:`, error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [categoryId, currentPage]);

  useEffect(() => {
    // Fetch post visits when categoryPosts change
    if (categoryPosts.length > 0) {
      categoryPosts.forEach((post) => {
        fetchPostVisits(post.id);
      });
    }
  }, [categoryPosts]);

  // Truncate the description to 50 characters
  const truncateDescription = (description) => {
    if (description.length > 60) {
      return description.substring(0, 60) + '...';
    }
    return description;
  };

  const generateShareLink = (socialMedia, post) => {
    const postUrl = `${process.env.REACT_APP_API_DOMAIN}/posts/${post.id}`;
    const title = he.decode(post.title.rendered);

    if (isValidUrl(postUrl)) {
      if (socialMedia === 'whatsapp') {
        return `https://wa.me/?text=${encodeURIComponent(`${title} - ${postUrl}`)}`;
      } else if (socialMedia === 'facebook') {
        return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(postUrl)}`;
      }
    } else {
      console.error('Invalid URL:', postUrl);
      return '';
    }
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleFacebookShare = (post) => {
    // Increment visits when sharing on Facebook
    incrementArticleVisits(post.id);

    if (isValidUrl(post.link)) {
      // Mettez à jour les balises Open Graph avant de partager sur Facebook
      updateOpenGraphTags(post);

      const facebookLink = generateShareLink('facebook', post);
      window.open(facebookLink, '_blank');
    } else {
      console.error('Invalid URL:', post.link);
    }
  };

  const updateOpenGraphTags = (post) => {
    // Mettez à jour les balises Open Graph selon les besoins
    // ...
  };

  return (
    <>
      <div className="container mx-auto mb-10 p-1 mt-20">
        <h1 className='lg:w-5/6 xl:w-4/5 2xl:w-3/4 mx-6 py-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-5 font-bold' style={{fontSize:20}}>
          {category.name}:
        </h1>

        {noResults ? (
          <>
            <p className="lg:w-5/6 xl:w-4/5 2xl:w-3/4 mx-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-5 ">Aucun contenu ajouté à cette catégorie.</p>
            <div style={{ height: 400 }}></div>
          </>
        ) : (
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-5' style={{ marginTop: '-20px' }}>
            {loading ? (
              <div className='col-span-3 w-4 h-4 absolute left-1/2 top-1/2 rounded-full border-4 border-b-0 border-blue-500 animate-spin'/>
            ) : categoryPosts.length ? (
              categoryPosts.map((post) => (
                <div key={post.id} className="card shadow-lg rounded-lg flex flex-col gap-5 p-2 bg-white">
                  <Link to={`/posts/${post.id}`} onClick={() => incrementArticleVisits(post.id)}>
                    <img
                      src={post.featured_src || 'https://via.placeholder.com/500'}
                      alt={post.title.rendered}
                      style={{ width: 500, height: 200 }}
                    />
                  </Link>
                  <Link to={`/posts/${post.id}`} onClick={() => incrementArticleVisits(post.id)}>
                    <p style={{ marginTop: -5, marginBottom: 10, fontSize: 12 }} className="text-gray-500 text-sm">
                      <FontAwesomeIcon icon={faCalendar} style={{ color: 'rgb(17 24 39)' }} />&nbsp; {new Date(postDates[post.id]).toLocaleDateString()}
                    </p>
                    <h2 className="text-lg font-medium" style={{ fontWeight: '700', fontSize: 16 }}>
                      {truncateDescription(he.decode(post.title.rendered), 60)}
                    </h2>
                  </Link>
                  <center>
                    <div>
                      <WhatsappShareButton
                        url={generateShareLink('whatsapp', post)}
                        title={he.decode(post.title.rendered)}
                        separator=":: "
                      >
                        <button
                          className='share-button  text-white bg-green-500'
                          disabled={!isValidUrl(generateShareLink('whatsapp', post))}
                          style={{ marginRight: '5px', padding: 8, fontSize: 16 }}
                        >
                          <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: '5px' }} className='share-icon'/>
                        </button>
                      </WhatsappShareButton>
                      &nbsp;&nbsp;
                      <FacebookShareButton
                        url={generateShareLink('facebook', post)}
                        quote={he.decode(post.title.rendered)}
                        hashtag="#YourHashtag"
                      >
                        <button
                          className='text-white bg-blue-500 share-button'
                          disabled={!isValidUrl(generateShareLink('facebook', post))}
                          style={{ fontSize: 16 }}
                          onClick={() => handleFacebookShare(post)}
                        >
                          <FontAwesomeIcon icon={faFacebook} style={{ marginRight: '5px' }} className='share-icon'/>
                        </button>
                      </FacebookShareButton>
                      <p style={{float:'right', fontSize:13}}><FontAwesomeIcon icon={faEye} style={{ color: 'rgb(17 24 39)' }} />&nbsp; {postVisits[post.id] || 0} visites</p>
                    </div>
                  </center>
                </div>
              ))
            ) : (
              <p>Aucun contenu ajouté à cette catégorie.</p>
            )}
          </div>
        )}

        {/* Pagination */}
        {!noResults && (
          <center>
            <div className="" style={{ marginBottom: '40px' }}>
              <button className='btn-primary text-white bg-blue-500 rounded-lg hover:shadow-lg disabled:opacity-50' disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)} style={{ marginRight: '5px', padding: 8, fontSize: 16, background: '#002763' }}>Précédent</button>
              <span style={{ margin: '0 5px', fontSize: 17 }}>{currentPage} sur {totalPages}</span>
              <button className='btn-primary text-white bg-blue-500 rounded-lg hover:shadow-lg disabled:opacity-50' onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages} style={{ marginLeft: '5px', padding: 8, fontSize: 16, background: '#002763' }}>Suivant</button>
            </div>
          </center>
        )}
      </div>        
      <CustomBottomNavigation/>
    </>
  );
};

export default CategoryArticles;
